const GENERIC_LOAD_REQUESTED = 'inquisit-app/dashboard/analytics/GENERIC_LOAD_REQUESTED';
const GENERIC_SUCCESS = 'inquisit-app/dashboard/analytics/GENERIC_SUCCESS';
const GENERIC_LOAD_FAIL = 'inquisit-app/dashboard/analytics/GENERIC_LOAD_FAIL';
const LOAD_SUBENTITIES_SUCCESS = 'inquisit-app/dashboard/analytics/LOAD_SUBENTITIES_SUCCESS';
const LOAD_BREAKDOWN_SUCCESS = 'inquisit-app/dashboard/analytics/LOAD_BREAKDOWN_SUCCESS';
const LOAD_BREAKDOWN_METRICS_SUCCESS = 'inquisit-app/dashboard/analytics/LOAD_BREAKDOWN_METRICS_SUCCESS';
const LOAD_CALL_DISPOSITIONS_SUCCESS = 'inquisit-app/dashboard/analytics/LOAD_CALL_DISPOSITIONS_SUCCESS';
const LOAD_CONVERSION_FUNNEL_SUCCESS = 'inquisit-app/dashboard/analytics/LOAD_CONVERSION_FUNNEL_SUCCESS';
const LOAD_CALL_DISPOSITIONS_BREAKDOWN_SUCCESS = 'inquisit-app/dashboard/analytics/LOAD_CALL_DISPOSITIONS_BREAKDOWN_SUCCESS';

export function getSubentitiesByOrgId(orgId) {
  return {
    types: [GENERIC_LOAD_REQUESTED, LOAD_SUBENTITIES_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/ivr/get_subentities_by_org_id', {
      params: { org_id: orgId }
    })
  };
}

export function getIVRCallMetrics(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/ivr/ivr_call_metrics', {
      params: {
        org_id: data.org_id,
        date_filter: data.date_filter,
        subentity_id: data.subentity_id
      }
    })
  };
}

export function getIVREngagementMetrics(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/dashboard/analytics/get_ivr_engagement_metrics', {
      params: data
    })
  };
}

export function getIVRResponseMetrics(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, GENERIC_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/dashboard/analytics/get_ivr_response_metrics', {
      params: data
    })
  };
}

export function getQueuedAndActedUponBreakdown(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, LOAD_BREAKDOWN_SUCCESS, GENERIC_LOAD_FAIL],
    breakdownRequest: true,
    promise: (client) => client.get('/admin/ivr/get_queued_and_acted_upon_by_agent_breakdown', {
      params: {
        org_id: data.org_id,
        date_filter: data.date_filter,
        subentity_id: data.subentity_id
      }
    })
  };
}

export function getBreakdownMetrics(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, LOAD_BREAKDOWN_METRICS_SUCCESS, GENERIC_LOAD_FAIL],
    breakdownRequest: true,
    promise: (client) => client.get('/admin/ivr/ivr_call_metrics', {
      params: {
        org_id: data.org_id,
        date_filter: data.date_filter,
        subentity_id: data.subentity_id
      }
    })
  };
}

export function getOutreachCallDispositions(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, LOAD_CALL_DISPOSITIONS_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/ivr/get_outreach_call_dispositions', {
      params: {
        org_id: data.org_id,
        date_filter: data.date_filter,
        subentity_id: data.subentity_id
      }
    })
  };
}

export function getOutreachConversionFunnel(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, LOAD_CONVERSION_FUNNEL_SUCCESS, GENERIC_LOAD_FAIL],
    promise: (client) => client.get('/admin/ivr/get_outreach_conversion_funnel', {
      params: {
        org_id: data.org_id,
        date_filter: data.date_filter,
        subentity_id: data.subentity_id
      }
    })
  };
}

export function getOutreachCallDispositionsBreakdown(data) {
  return {
    types: [GENERIC_LOAD_REQUESTED, LOAD_CALL_DISPOSITIONS_BREAKDOWN_SUCCESS, GENERIC_LOAD_FAIL],
    breakdownRequest: true,
    promise: (client) => client.get('/admin/ivr/outreach_call_dispositions_breakdown', {
      params: {
        org_id: data.org_id,
        date_filter: data.date_filter,
        subentity_id: data.subentity_id,
        otx_status_id: data.otx_status_id
      }
    })
  };
}

// Initial state
const initialState = {
  loading: false,
  loaded: false,
  breakdownLoading: false,
  error: null,
  data: null,
  subentities: [],
  breakdown: null,
  breakdownMetrics: null,
  callDispositions: null,
  conversionFunnel: null,
  callDispositionsBreakdown: null
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GENERIC_LOAD_REQUESTED:
      return {
        ...state,
        loading: action.breakdownRequest ? false : true,
        breakdownLoading: action.breakdownRequest ? true : false,
        loaded: false
      };
    case GENERIC_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case GENERIC_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOAD_SUBENTITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        subentities: action.result.data || []
      };
    case LOAD_BREAKDOWN_SUCCESS:
    case LOAD_BREAKDOWN_METRICS_SUCCESS:
      return {
        ...state,
        breakdownLoading: false,
        loaded: true,
        [action.type === LOAD_BREAKDOWN_SUCCESS ? 'breakdown' : 'breakdownMetrics']: action.result
      };
    case LOAD_CALL_DISPOSITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        callDispositions: action.result
      };
    case LOAD_CONVERSION_FUNNEL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        conversionFunnel: action.result
      };
    case LOAD_CALL_DISPOSITIONS_BREAKDOWN_SUCCESS:
      return {
        ...state,
        breakdownLoading: false,
        loaded: true,
        callDispositionsBreakdown: action.result
      };
    default:
      return state;
  }
} 