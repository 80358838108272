const GENERIC_REQUEST = 'inquisit-app/es/GENERIC_REQUEST';
const GENERIC_SUCCESS = 'inquisit-app/es/GENERIC_SUCCESS';
const GENERIC_FAIL = 'inquisit-app/es/GENERIC_FAIL';

const MENTEE_PROFILE_REQUEST = 'inquisit-app/es/MENTEE_PROFILE_REQUEST'
const MENTEE_PROFILE_SUCCESS = 'inquisit-app/es/MENTEE_PROFILE_SUCCESS'
const MENTEE_PROFILE_FAIL = 'inquisit-app/es/MENTEE_PROFILE_FAIL'
const PYX_MENTEE_PROFILE_SUCCESS = 'inquisit-app/mentee/PYX_MENTEE_PROFILE_SUCCESS';


const ES_MENTOR_LIST_SUCCESS = 'inquisit-app/es/ES_MENTOR_LIST_SUCCESS'
// const ES_MENTEE_LIST_SUCCESS = 'inquisit-app/es/ES_MENTEE_LIST_SUCCESS';
// const ES_MENTEE_LIST_REQUEST = 'inquisit-app/es/ES_MENTEE_LIST_REQUEST';
const UPDATE_MENTEE_LIST = 'inquisit-app/es/UPDATE_MENTEE_LIST';

const MENTEE_AVAILABILITY = 'inquisit-app/es/MENTEE_AVAILABILITY';
const MENTEE_RECENT_CALL = 'inquisit-app/es/MENTEE_RECENT_CALL';

const ES_MENTEE_LIST_FILTERS_SUCCESS = 'inquisit-app/es/ES_MENTEE_LIST_FILTERS_SUCCESS';


const UPDATE_ES_BASE_SUCCESS = 'inquisit-app/es/UPDATE_ES_BASE_SUCCESS';

const SAVE_SELECTED_FILTERS = 'inquisit-app/es/SAVE_SELECTED_FILTERS';
const SET_MENTEE_TASKS = 'inquisit-app/es/SET_MENTEE_TASKS';
const SET_MENTEE_TASK_ENUMS = 'inquisit-app/es/SET_MENTEE_TASK_ENUMS';
const UPDATE_SCHEDULED_DATE_TIME = 'inquisit-app/es/UPDATE_SCHEDULED_DATE_TIME';
const UPDATE_MENTEE_PROFILE = 'inquisit-app/es/UPDATE_MENTEE_PROFILE';
const RESET_MENTEE_PROFILE = 'inquisit-app/es/RESET_MENTEE_PROFILE';
const UPDATE_CALL_FOOTER = 'inquisit-app/es/UPDATE_CALL_FOOTER';
const UPDATE_OPEN_ISSUE = 'inquisit-app/es/UPDATE_OPEN_ISSUE';

const SAVE_PRE_CALL_TIMES = 'inquisit-app/es/SAVE_PRE_CALL_TIMES';
const SAVE_POST_CALL_TIMES = 'inquisit-app/es/SAVE_POST_CALL_TIMES';

const initialState = {
  loading: true,
  // esList: [],
  profileLoading: true,
  menteeProfile: {},
  pyxMenteeProfile: {},
  latestCallTx: null,
  enrollmentCallTx: null,
  esMentorList: [],
  menteeAvailability: { __html: 'Loading...' },
  filters: {
    orgs: [],
    phases: []
  },
  selectedFilters: {
    listingTypeOption: 'all',
    status: null,
    availability: null,
    esStatus: null,
    esMentor: null,
    messagingStatus: null,
    organization: null,
    phase: null,
    issuesWithoutTracks: null,
    activeIssuesSixtyPlusDays: null,
    pendingClinicalUpdateActions: null,
  },
  stats: {
    'listing_filters': {}
  },
  // bigNumbers: [],
  callFooter: null,
  openIssue: false,
  preCallPlanningTimes: {
  },
  postCallChecklistTimes: {
  }
  // selectedSort: 'systemPrioritized' // systemPrioritized, nextCall, lastAttemptAsc, lastAttemptDesc
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MENTEE_PROFILE_REQUEST:
      return {
        ...state,
        profileLoading: true
      }
    case MENTEE_PROFILE_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        menteeProfile: (action.result.mentee_profile) ? action.result.mentee_profile : {},
        latestCallTx: (action.result.call_tx) ? action.result.call_tx : null,
        // enrollmentCallTx: (action.result.enrollment_call_tx) ? action.result.enrollment_call_tx : null,
        callFooter: null
      }
    case PYX_MENTEE_PROFILE_SUCCESS:
        return {
          ...state,
          profileLoading: false,
          pyxMenteeProfile: action.result
            ? action.result
            : {},
        };
    case MENTEE_RECENT_CALL:
      return {
        ...state,
        latestCallTx: (action.result.call_tx) ? action.result.call_tx : null
      }
    case MENTEE_AVAILABILITY:
      return {
        ...state,
        menteeAvailability: (action.result.mentee_availability)
          ? { __html: action.result.mentee_availability, surveyResponse: action.result.mentee_availability_survey_response, availabilityNotes: action.result.availability_notes }
          : { __html: 'Mentee availability information has not been entered.', surveyResponse: action.result.mentee_availability_survey_response, availabilityNotes: action.result.availability_notes }
      }
    case MENTEE_PROFILE_FAIL:
      return {
        ...state,
        profileLoading: false
      }
    case SET_MENTEE_TASKS:
      return {
        ...state,
        menteeProfile: {
          ...state.menteeProfile,
          pyx_tasks: action.payload
        }
      };  
      case SET_MENTEE_TASK_ENUMS:
        return {
          ...state,
        menteeProfile: {
          ...state.menteeProfile,
          pyx_task_enums: action.payload
        }
      };  
    case ES_MENTOR_LIST_SUCCESS:
      return {
        ...state,
        esMentorList: (action.result.es_mentor_list) ? action.result.es_mentor_list : []
      }
    case ES_MENTEE_LIST_FILTERS_SUCCESS:
      return {
        ...state,
        filters: {
          orgs: (action.result && action.result.orgs) ? action.result.orgs : [],
          phases: (action.result && action.result.phases) ? action.result.phases : []
        }
      }
    // case ES_MENTEE_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     esList: (action.result && action.result.list) ? action.result.list : [],
    //     stats: (action.result && action.result.supporting_population_stats) ? action.result.supporting_population_stats : {},
    //     bigNumbers: (action.result && action.result.big_numbers) ? action.result.big_numbers : []
    //   }
    // case UPDATE_MENTEE_LIST:
    //   return {
    //     ...state,
    //     esList: (action.result) ? action.result : []
    //   }
    case UPDATE_MENTEE_PROFILE: {
      let mp = action.result.update ? { ...state.menteeProfile, ...action.result } : action.result;
      return {
        ...state,
        profileLoading: false,
        menteeProfile: mp
      }
    }
    case RESET_MENTEE_PROFILE: {
      return {
        ...state,
        menteeProfile: {}
      }
    }
    case UPDATE_ES_BASE_SUCCESS: {
      if (!action.result.success) return { ...state };

      let { menteeProfile } = state;
      menteeProfile = { ...menteeProfile, ...action.result.updated_parameters };

      return {
        ...state,
        menteeProfile: menteeProfile
      }
    }
    case SAVE_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: action.result
      }
    case UPDATE_SCHEDULED_DATE_TIME: {
      if (!action.result.success) return { ...state };

      let menteeProfileUpd = state.menteeProfile;
      menteeProfileUpd = { ...menteeProfileUpd, ...action.result.updated_parameters }

      return {
        ...state,
        menteeProfile: menteeProfileUpd
      }
    }
    case UPDATE_CALL_FOOTER:
      return { ...state, callFooter: action.result }
    case UPDATE_OPEN_ISSUE:
      return { ...state, openIssue: action.result }

    case SAVE_PRE_CALL_TIMES:
      return { 
        ...state, 
        preCallPlanningTimes: action.result?.times ? {
          ...state.preCallPlanningTimes,
          [action.result.id]: {
            ...state.preCallPlanningTimes[action.result.id],
            ...action.result.times
          }
        } : {
          ...state.preCallPlanningTimes,
          [action.result.id]: undefined // Delete the mentee key if null is passed
        }
      }

    case SAVE_POST_CALL_TIMES:
      return { 
        ...state, 
        postCallChecklistTimes: action.result?.times ? {
          ...state.postCallChecklistTimes,
          [action.result.id]: {
            ...state.postCallChecklistTimes[action.result.id],
            ...action.result.times
          }
        } : {
          ...state.postCallChecklistTimes,
          [action.result.id]: undefined // Delete the mentee key if null is passed
        }
      }

    default:
      return state;
  }
}

/*
  *******
  *******
   Get
  *******
  *******
  */

// export function getESMenteeList(data) {
//   return {
//     types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
//     promise: (client) => client.get('/es/es_mentee_list', {
//       params: data
//     })
//   };
// }

export function getESMenteeListFilters(data) {
  return {
    types: [GENERIC_REQUEST, ES_MENTEE_LIST_FILTERS_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/es_mentee_list_filters', {
      params: data
    })
  };
}

export function getMenteeProfile(data) {
  const showLoadingInd = data.showLoadingInd;
  if (showLoadingInd) delete data['showLoadingInd'];
  return {
    types: [showLoadingInd ? MENTEE_PROFILE_REQUEST : GENERIC_REQUEST, MENTEE_PROFILE_SUCCESS, MENTEE_PROFILE_FAIL],
    promise: (client) => client.get('/es/es_mentee_profile', {
      params: data
    })
  };
}

export function getCallTxWithMissingTaskGuidByTypeGroupAndId(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/get_call_tx_with_missing_task_guid_by_type_group_and_id', {
      params: data
    })
  };
}

export function getPyxMenteeProfile(pyxUserId) {
  return {
    types: [MENTEE_PROFILE_REQUEST, PYX_MENTEE_PROFILE_SUCCESS, MENTEE_PROFILE_FAIL],
    promise: client =>
      client.get(`:pyxapi/v5/Members/${pyxUserId}`),
  };
}

export function getPyxMenteeNgm(orgId, ngmId) {
  return {
    types: [MENTEE_PROFILE_REQUEST, PYX_MENTEE_PROFILE_SUCCESS, MENTEE_PROFILE_FAIL],
    promise: client =>
      client.get(`:pyxapi/v5/Ngms/${orgId}:${ngmId}`),
  };
}

export function setDoNotCall(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post(':pyxapi/v1/DoNotCall', {
      data: data
    })
  };
}

export function removeDoNotCall(doNotCallGuid, pyxOrganizationId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.del(`:pyxapi/v1/DoNotCall/${doNotCallGuid}`, {
      data: {
        guid: doNotCallGuid,
        organizationId: pyxOrganizationId
      }
    })
  };
}

export function getMenteeProgramData(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/get_mentee_program_data', {
      params: data
    })
  };
}

export function getMenteeProfileShort(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/es_mentee_profile_short', {
      params: data
    })
  };
}

export function getTalkingPoints(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/talking_points', {
      params: data
    })
  };
}

export function getTalkingPointSets(rootId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/talking_point_sets', {
      params: {
        root_id: rootId
      }
    })
  };
}

export function getMaterialsSent(menteeId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/materials_sent', {
      params: {
        mentee_id: menteeId
      }
    })
  };
}

export function getESMentorsList(data) {
  return {
    types: [GENERIC_REQUEST, ES_MENTOR_LIST_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/get_es_mentors_list', {
      params: data
    })
  };
}

export function getESProgramPhases(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/get_es_program_phases', {
      params: data
    })
  };
}

export function updateMenteeProfile(data) {
  return {
    type: UPDATE_MENTEE_PROFILE,
    result: data
  };
}

export function resetMenteeProfile() {
  return {
    type: RESET_MENTEE_PROFILE,
  };
}

export function getESCallSchedulingBindings(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/es_call_scheduling_bindings', {
      params: data
    })
  };
}

export function getESAvailableTimesByDate(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/es_available_times_by_date', {
      params: data
    })
  };
}

export function getESCallSchedulingSuggestions(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/es_call_scheduling_suggestions', {
      params: data
    })
  };
}

export function getApplicableTimezone(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/es/get_applicable_timezone', {
      params: data
    })
  };
}

export function getScheduledActivity(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('es/get_scheduled_activity', {
      params: data
    })
  };
}

export function getScheduledDateTime(data) {
  return {
    types: [GENERIC_REQUEST, UPDATE_SCHEDULED_DATE_TIME, GENERIC_FAIL],
    promise: (client) => client.get('es/get_scheduled_date_time', {
      params: data
    })
  };
}

export function getMentorMessagesOverview(mentorId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('es/get_mentor_messages_overview', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function getMentorMessagesCount(mentorId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('es/get_mentor_messages_count', {
      params: {
        mentor_id: mentorId
      }
    })
  };
}

export function markMentorMessagesRead(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('es/mark_mentor_messages_read', {
      data: data
    })
  };
}

export function getESMenteeStringifiedAvailability(data) {
  return {
    types: [GENERIC_REQUEST, MENTEE_AVAILABILITY, GENERIC_FAIL],
    promise: (client) => client.get('es/es_mentee_stringified_availability', {
      params: data
    })
  };
}

export function getESMenteeRecentCall(data) {
  return {
    types: [GENERIC_REQUEST, data.fetch_recent_ind === 0 ? GENERIC_SUCCESS : MENTEE_RECENT_CALL, GENERIC_FAIL],
    promise: (client) => client.get('es/es_mentee_recent_call', {
      params: data
    })
  };
}

export function getESMentorCalls(data) {
  return {
    types: [GENERIC_REQUEST, data.fetch_recent_ind === 0 ? GENERIC_SUCCESS : MENTEE_RECENT_CALL, GENERIC_FAIL],
    promise: (client) => client.get('es/get_es_mentor_calls', {
      params: data
    })
  };
}

export function getESMenteeMonthlyActivity(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('es/get_es_mentee_timeline_snapshot', {
      params: data
    })
  };
}

export function getMenteeNotes(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('es/get_mentee_notes', {
      params: data
    })
  };
}

export function getMenteeSupporterMatches(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('es/get_mentee_supporter_matches', {
      params: data
    })
  };
}

/*
  *******
  *******
   Set
  *******
  *******
  */
export function updateCallTxStatus(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/es/update_es_call_tx_status', {
      data: {
        mentee_id: data.mentee_id,
        call_tx_id: data.call_tx_id,
        pyx_task_data: data.pyx_task_data,
        status: data.status
      }
    })
  };
}

export const setMenteePyxTasks = (tasks) => ({
  type: SET_MENTEE_TASKS,
  payload: tasks
});

export const setMenteePyxTaskEnums = (enums) => ({
  type: SET_MENTEE_TASK_ENUMS,
  payload: enums
});

export function updateEngagedSupportProgramBase(data) {
  return {
    types: [GENERIC_REQUEST, UPDATE_ES_BASE_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/es/update_engaged_support_program_base', {
      data: data
    })
  };
}


export function moveMenteeToEngagedSupportProgram(menteeId, transferReason) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/es/move_mentee_to_engaged_support_program', {
      data: {
        mentee_id: menteeId,
        transfer_reason: transferReason
      }
    })
  };
}

export function scheduleESCall(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/es/schedule_reschedule_call', {
      data: data
    })
  };
}

export function cancelESCall(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/es/cancel_call', {
      data: data
    })
  };
}

export function reassignMenteesToSelectedMentor(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/es/reassign_mentees_to_selected_mentor', {
      data: data
    })
  };
}

export function updateESPhase(data) {
  return {
    types: [GENERIC_REQUEST, UPDATE_ES_BASE_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/es/update_es_phase', {
      data: data
    })
  };
}

export function updatePNPhase(data) {
  return {
    types: [GENERIC_REQUEST, UPDATE_ES_BASE_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/es/update_pn_phase', {
      data: data
    })
  };
}

export function ungraduateMentee(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/es/ungraduate_mentee', {
      data: data
    })
  };
}

export function closeEsNode(menteeId, menteeMasterId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/es/close_es_call_node', {
      data: {
        mentee_id: menteeId,
        mentee_master_id: menteeMasterId
      }
    })
  };
}

export function saveSelectedFilters(filters) {
  return {
    type: SAVE_SELECTED_FILTERS,
    result: filters
  };
}

export function updateESMenteeList(esMenteeList) {
  return {
    type: UPDATE_MENTEE_LIST,
    result: esMenteeList
  };
}

export function updatePreCallPlanningTimes(id, times) {
  console.log('here', times, id);
  return {
    type: SAVE_PRE_CALL_TIMES,
    result: {
      id, 
      times
    }
  };
}

export function updatePostCallChecklistTimes(id, times) {
  return {
    type: SAVE_POST_CALL_TIMES,
    result: {
      id, 
      times
    }
  };
}

export function setMenteeNote(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: client =>
      client.post('/es/setmenteedata/process_note', {
        data: data,
      }),
  };
}

export function updateMenteeNote(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: client =>
      client.post('/es/setmenteedata/update_mentee_note', {
        data: data,
      }),
  };
}

export function deleteMenteeNote(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: client =>
      client.post('/es/setmenteedata/delete_mentee_note', {
        data: data,
      }),
  };
}

export function resendMaterial(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: client =>
      client.post('/es/resend_material', {
        data: data,
      }),
  };
}

export function setCallFooter(data) {
  return {
    type: UPDATE_CALL_FOOTER,
    result: data
  }
}

export function updateOpenIssue(data) {
  return {
    type: UPDATE_OPEN_ISSUE,
    result: data
  }
}

export function getLastCallForSchedulingType(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: client => 
      client.get('/es/get_mentee_last_call_for_peer', {
        params: data,
      }),
  }
}

export function getStatusInfoForPostCallModal(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: client => 
      client.get('/es/get_status_info_for_post_call_modal', {
        params: data,
      }),
  }
}

export function menteeInfoForFreeAgentCallList(data) {
  data['et_ind'] = 1;
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: client => 
      client.post('/es/mentee_info_for_free_agent_call_list', {
        data: data,
      }),
  }
}
